import { MediaQueries } from "@/types/enum";
import { useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

export const useIsFocusedInput = () => {
  const [isFocusedInput, setFocusedInput] = useState(false);

  const isMobile = useMediaQuery(MediaQueries.TABLET);

  const handleFocusChange = useCallback((event: FocusEvent) => {
    if (isMobile) {
      setFocusedInput(event.type === 'focusin');
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener("focusin", handleFocusChange);
    window.addEventListener("focusout", handleFocusChange);

    return () => {
      window.removeEventListener("focusin", handleFocusChange);
      window.removeEventListener("focusout", handleFocusChange);
    }
  }, [isMobile]);

  return isFocusedInput
}
import { yupResolver } from '@hookform/resolvers/yup';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Modal } from '@/components/Modals/Modal';
import { GoogleAuth } from '@/components/Modals/ModalAuthContainer/ModalAuthMagicLink/GoogleAuth';
import { ModalAuthProps } from '@/components/Modals/ModalAuthContainer/types.ts';
import { Spinner } from '@/components/Spinner';
import {
  ANONYMOUS_USER_ID_KEY,
  CONFIRMED_TERMS_KEY,
  REGISTERED_BEFORE_KEY,
} from '@/const';
import {
  MAGIC_LINK_SENT_EVENT,
  SIGN_IN_BUTTON_CLICKED_EVENT,
  USER_SIGNED_UP_EVENT,
} from '@/const/dataLayerEvents.ts';
import { FingerprintData, useFingerprint } from '@/hooks/useFingerprint.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks/useTSRedux.tsx';
import { usePasswordLessLoginMutation } from '@/services/authServices';
import {
  LoginFormValues,
  PasswordLessLoginData,
} from '@/services/authServices/types.ts';
import { selectUser, setAuth } from '@/store/public';
import { Routes } from '@/types/enum.ts';
import { getFingerprintHash } from '@/utils/getFingerprintHash.ts';
import { getIsNSFWUser } from '@/utils/getIsNSFWUser.ts';
import { pushToDataLayer } from '@/utils/gtm.ts';
import { ls } from '@/utils/localStorage.ts';

import { schema } from './schema.ts';

import styles from './styles.module.scss';

import MagicLinkExpiredIcon from '@/assets/icons/auth/link-expired.svg?react';
import MagicLinkIcon from '@/assets/icons/auth/magic-link.svg?react';
import MailIcon from '@/assets/icons/auth/mail.svg?react';

export const ModalAuthMagicLink = ({
  onClose,
  variant,
  setVariant,
  onSuccess,
  setIsMagicLinkForm,
}: ModalAuthProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);

  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);

  const isLogin = variant === 'login';
  const isExpiredLink = variant === 'expired';
  const isNSFW = getIsNSFWUser(user);

  const [login, { data: passwordLessLoginData, isLoading, isError, error }] =
    usePasswordLessLoginMutation();
  const { postSetFingerprint } = useFingerprint();

  useEffect(() => {
    if (variant) setVariant(variant);
  }, [variant]);

  useEffect(() => {
    if (
      passwordLessLoginData?.accessToken &&
      passwordLessLoginData?.refreshToken
    ) {
      pushToDataLayer(USER_SIGNED_UP_EVENT, user, {
        auth_method: 'email',
        source: 'sign_up_button',
      });
      const { accessToken, refreshToken } = passwordLessLoginData;
      dispatch(setAuth({ accessToken, refreshToken }));
      ls.set(REGISTERED_BEFORE_KEY, 'true');
      postSetFingerprint();
      onSuccess?.();
      handleClose();
      navigate(Routes.MAIN_PAGE);
    }
  }, [passwordLessLoginData]);

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LoginFormValues> = async (data) => {
    const fingerprintData = (await getFingerprint(true)) as FingerprintData;
    await loginSubmit(data, fingerprintData);
  };

  const loginSubmit = async (
    data: LoginFormValues,
    fingerprintData: FingerprintData,
  ) => {
    isLogin && pushToDataLayer(SIGN_IN_BUTTON_CLICKED_EVENT, user);

    const hasConfirmedTerms = ls.get(CONFIRMED_TERMS_KEY, '');
    const loginDataPayload: PasswordLessLoginData = {
      Email: data.Email?.trim(),
      Fingerprint: getFingerprintHash(fingerprintData),
      IsNConfirmed: hasConfirmedTerms === 'n',
      IsSConfirmed: !!hasConfirmedTerms,
      UserId: ls.get(ANONYMOUS_USER_ID_KEY, ''),
    };

    const { data: loginData, error } = await login(loginDataPayload);
    if (!loginData?.accessToken && !error) {
      setIsMagicLinkSent(true);
      pushToDataLayer(MAGIC_LINK_SENT_EVENT, user);
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setIsMagicLinkSent(false);
    }, 300);
    onClose();
  };

  const renderSentMagicLink = () => (
    <div className={styles.sentMagicLink}>
      <MagicLinkIcon />
      <p id="text_magic_wind" className={styles.magicLinkTitle}>
        We've sent a magic link to your email!
      </p>
      <p className={styles.magicLinkDescription}>
        Please check your inbox and click the link to sign in.
        <br />
        If you don't see the email or have any issues, please{' '}
        <Link to={Routes.CONTACT_US_PAGE} onClick={onClose}>
          contact us
        </Link>{' '}
        for assistance.
      </p>
    </div>
  );

  const renderMagicLinkExpired = () => (
    <div className={styles.sentMagicLink}>
      <MagicLinkExpiredIcon />
      <p className={styles.magicLinkTitle}>Link Expired</p>
      <p className={styles.magicLinkDescription}>
        Oops! Looks like your magic link has expired. No worries, just request a
        new one to get back into your account.
      </p>
      <Button onClick={() => setVariant('login')} fullWidth>
        Get a new link
      </Button>
    </div>
  );

  return (
    <>
      <Modal onClose={handleClose} maxWidth={655}>
        {isMagicLinkSent ? (
          renderSentMagicLink()
        ) : isExpiredLink ? (
          renderMagicLinkExpired()
        ) : (
          <>
            <h2 id="text" className={styles.title}>
              {isLogin ? 'Login' : 'Create Account'}
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.input}>
                <MailIcon />
                <input
                  id="email_field"
                  className={styles.inputStyle}
                  placeholder="E-mail"
                  {...formRegister('Email')}
                />
              </div>
              {errors.Email && (
                <p id="email_field_error" className={styles.error}>
                  {errors.Email.message}
                </p>
              )}

              <p
                id="error_from_back"
                className={classNames(styles.error, !isError && styles.hidden)}
              >
                {(error as any)?.data?.errors?.[0]?.errorMessage ||
                  'Something went wrong. Please try again.'}
              </p>

              <Button
                id="enter_btn"
                className={styles.signIn}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : isLogin ? 'Login' : 'Create'}
              </Button>
            </form>
            <p className={styles.or}>or</p>
            <GoogleAuth />

            {isLogin ? (
              <div className={styles.notHaveAccount}>
                Don't have an account yet?{' '}
                <button id="switch_btn" onClick={() => setVariant('register')}>
                  Register
                </button>
              </div>
            ) : (
              <div className={styles.terms}>
                By signing up, you agree to{' '}
                <Link
                  to={Routes.TERMS_OF_SERVICE_PAGE}
                  onClick={handleClose}
                  id="terms_btn"
                >
                  Terms of service
                </Link>
              </div>
            )}

            {!isLogin && (
              <p className={styles.notHaveAccount}>
                Already have an account yet?{' '}
                <button id="switch_btn" onClick={() => setVariant('login')}>
                  Login
                </button>
              </p>
            )}

            {isLogin && !isNSFW && (
              <p className={styles.passwordAuthText}>
                We’ll email you a magic link code for a password-free sign in.
                <br />
                Or you can{' '}
                <button
                  id="manual_log_btn"
                  onClick={() => setIsMagicLinkForm?.(false)}
                >
                  sign in manually instead
                </button>
                .
              </p>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
